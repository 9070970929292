@font-face {
  font-family: "Tiresias";
  font-style: normal;
  font-weight: normal;
  src: local("Tiresias"), url("../fonts/TiresiasMinus.woff2") format("woff2");
}

@font-face {
  font-family: "FyranSans";
  font-style: normal;
  font-weight: normal;
  src: local("FyranSans"), url("../fonts/fyran-sans-regular.woff2")
    format("woff2");
}

body {
  overflow: hidden;
  /* TODO uses chromecast default margins.
     TODO Not having these here makes background to have black border,
     TODO Having these here risks that part of video element is outside of visible screen area.
  margin: 0;
  padding: 0;
  */
  margin: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  font-family: "FyranSans";
}

#receiver-video-element {
  height: 100%;
  width: 100%;
  display: block;
  z-index: 1;
  position: absolute;
}

video.background-enabled {
  background-color: #000;
}

/* hide native text track as we're using custom rendered one */
video::cue {
  visibility: hidden;
}

#html-text-tracks {
  z-index: 1;
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

#html-text-tracks #text-wrapper #text-content {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.font-size-small #html-text-tracks #text-wrapper #text-content {
  font-size: 16px;
  font-family: "Tiresias";
}

.font-size-medium #html-text-tracks #text-wrapper #text-content {
  font-size: 24px;
  font-family: "Tiresias";
}

.font-size-large #html-text-tracks #text-wrapper #text-content {
  font-size: 32px;
  font-family: "Tiresias";
}

.font-size-xlarge #html-text-tracks #text-wrapper #text-content {
  font-size: 40px;
  font-family: "Tiresias";
}

/* below styles apply only if Shaka's UITextDisplayer is in use */
.shaka-text-container {
  z-index: 1;
}

.shaka-text-wrapper > span > span > span {
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 5px 10px;
  border-radius: 5px;
}

.font-size-small .shaka-text-wrapper span span span span {
  font-size: 24px !important;
  font-family: "Tiresias" !important;
}

.font-size-medium .shaka-text-wrapper span span span span {
  font-size: 32px !important;
  font-family: "Tiresias" !important;
}

.font-size-large .shaka-text-wrapper span span span span {
  font-size: 40px !important;
  font-family: "Tiresias" !important;
}

.font-size-xlarge .shaka-text-wrapper span span span span {
  font-size: 48px !important;
  font-family: "Tiresias" !important;
}
